<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="1">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="listType"
            v-model="params.type"
            @change="onChangeType"
            label="Type"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" v-if="params.type == 'Month'">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" v-if="params.type == 'Date'">
          <v-menu
            v-if="params.type !== 'Invoice'"
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" v-if="params.type == 'Date'">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" v-if="params.type == 'Invoice'">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            :items="listInvoice"
            v-model="invoice"
            return-object
            item-text="invoiceNumber"
            @change="onChangeInvoice"
            label="Invoice"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="1" class="py-0">
          <v-checkbox v-model="params.reExport" label="Re-Export"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-btn color="primary" @click="search" v-if="params.type !== 'Invoice'" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="download">
            <v-icon>
              mdi-export
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="params.type !== 'Invoice'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">No</th>
              <th class="text-center">Invoice Number</th>
              <th class="text-center">Invoice Date</th>
              <th class="text-center">Customer</th>
              <th class="text-center">Selected</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.invoiceNumber }}</td>
              <td class="text-center">{{ item.invoiceDate }}</td>
              <td class="text-center">{{ item.customerName }}</td>
              <td class="text-center">
                <v-checkbox dense v-model="item.selected"></v-checkbox>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";
import moment from "moment";

export default {
  name: "export-daftar-pajak",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        id: 0,
        typeInv: "",
        startDate: moment().format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
        type: "Date",
        reExport: false,
      },
      listType: ["Date", "Month", "Invoice"],
      items: [],
      listInvoice: [],
      invoice: {},
    };
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/searchInvoice", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async download() {
      this.$store.commit("SET_LOADER", true);
      if (this.params.type == "Invoice") {
        await this.$store
          .dispatch("report/exportPajak", {
            id: this.params.id,
            type: this.params.typeInv,
          })
          .then(response => {
            fileDownload(
              response.data,
              "ExportPajakInvoice-" + this.invoice.invoiceNumber + ".csv"
            );
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        if (this.items.length === 0) {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Invoice harus di pilih",
          });
        } else {
          let body = this.items.filter(x => x.selected);
          await this.$store
            .dispatch("report/exportPajakAll", body)
            .then(response => {
              fileDownload(response.data, "ExportPajakInvoiceBy-" + this.params.type + ".csv");
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        }
      }
    },
    async getDropdown() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/getDropdownPajak", { reExport: this.params.reExport })
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangeInvoice(val) {
      this.params.id = val.id;
      this.params.typeInv = val.type;
    },
    onChangeType(val) {
      if (val === "Invoice") {
        this.getDropdown();
      }
    },
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
